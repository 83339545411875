export default [
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/dashboard/home/Home.vue'),
  },
  {
    path: '/stat',
    name: 'stat',
    component: () => import('@/views/dashboard/stat/Stat.vue'),
  },
]
